var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-7"},[_c('v-card-text',{staticClass:"pa-5"},[_c('div',{staticClass:"d-sm-flex flex-column align-center justify-space-between"},[_c('div',{staticClass:"ml-3 d-flex justify-space-between w-100"},[_c('div',[_c('h2',{staticClass:"font-weight-regular title"},[_vm._v(" Secciones ")]),_c('h6',{staticClass:"body-2 mb-1 subtitle-2 font-weight-light"},[_vm._v(" Datos de registrados ")])])]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headerArray,"items":_vm.sections,"options":_vm.options,"loading":_vm.loading,"items-per-page":_vm.pageSize,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({name: 'SectionDetail', params : {id: item.id }})}}},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.nombre)+" ")])])]}},{key:"item.fecha_inicio",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({name: 'SectionDetail', params : {id: item.id }})}}},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(_vm.getDateFormat(item.fecha_inicio) || '-')+" ")])])]}},{key:"item.fecha_fin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({name: 'SectionDetail', params : {id: item.id }})}}},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(_vm.getDateFormat(item.fecha_fin) || '-')+" ")])])]}},{key:"item.meta_alumnos_inscritos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({name: 'SectionDetail', params : {id: item.id }})}}},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.numero_matriculas)+"/"+_vm._s(item.meta_alumnos_inscritos || '-')+" ")])])]}},{key:"item.monto_total_ganado",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({name: 'SectionDetail', params : {id: item.id }})}}},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.monto_total_ganado || 0)+" ")])])]}},{key:"item.alumnos_costo_cero",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({name: 'SectionDetail', params : {id: item.id }})}}},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.alumnos_costo_cero || 0)+" ")])])]}},{key:"item.canales",fn:function(ref){
var item = ref.item;
return [_c('WatchButton',{attrs:{"loading":_vm.setLoadingModal(item)},on:{"click":function($event){return _vm.watchCanals(item.id)}}})]}},{key:"item.exportar_estudiantes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"download-button",attrs:{"loading":item.loading || false,"color":"#FFF"},on:{"click":function($event){return _vm.exportStudents(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"pagination d-flex justify-space-between ml-auto mr-0 mt-5"},[_c('p',{staticClass:"ma-auto"},[_vm._v(_vm._s(_vm.page)+" de "+_vm._s(_vm.totalPages))]),_c('div',[_c('v-btn',{attrs:{"icon":"","disabled":_vm.page === 1},on:{"click":function($event){_vm.page--}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.page === _vm.totalPages},on:{"click":function($event){_vm.page++}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)])]},proxy:true}])})],1),_c('CanalsModal',{attrs:{"persistent":"","detail":_vm.currentModal || []},on:{"close":function($event){_vm.openModalCanals=false}},model:{value:(_vm.openModalCanals),callback:function ($$v) {_vm.openModalCanals=$$v},expression:"openModalCanals"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }