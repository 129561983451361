<template>
  <v-dialog
      v-bind="$attrs"
      class="modal rounded-xl ma-auto"
      :max-width="$vuetify.breakpoint.smAndDown ? '95%': '40%'"
      :min-height="$vuetify.breakpoint.smAndDown ? '350': '100%'"
      style="border: 1px solid red !important;"
      v-on="$listeners"
  >
    <v-card
        class="rounded-xl py-7 mx-auto"
        width="100%"
        color="#EEF5F9"
    >
      <v-btn icon style="position: absolute; top: 15px; right: 15px;" @click="$emit('close')">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      <p class="list__title mb-10 text-center">
        Canales de Referencia
      </p>
      <div v-if="detail.length" class="list">
        <div
            v-for="(item, index) in detail"
            :key="index"
            v-ripple
            class="list__item d-flex align-center justify-space-between px-4 mx-auto mb-6 pointer"
        >
          <p class="list__text ma-0">
            {{ item.nombre_canal }}
          </p>
          <div class="d-flex align-center justify-space-between">
            <p class="list__points ma-0 mr-2">
              {{ item.cantidad }}
            </p>
            <p class="list__points ma-0" style="width: 100%;">
              ({{ item.porcentaje }} %)
            </p>
          </div>
        </div>
      </div>
      <h4 v-else class="mx-auto text-center">
        Aun no posee canales de referencia
      </h4>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    detail: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    mdAndDown () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #455A64;
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #455A64;
    margin: 0px 200px;
  }
  &__item {
    border: 1px solid #D8D8D8;
    border-radius: 100px;
    height: 42px;
    width: 80%;
  }
}
.bounce-enter-active {
  animation: bounce-in 1s;
}

.bounce-leave-active {
  animation: bounce-in 1s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.modal {
  &__list {
    font-size: 14px;
    line-height: 16px;
    color: #647485;
    border-bottom: 0.5px solid #D3D3D3;
    width: 90% !important;
  }

  &__border {
    border-radius: 40px !important;
  }

  &__title {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #455A64;
  }

  &__btn {
    text-transform: none !important;
    background: #FFF !important;
    border: 1px solid #707070 !important;
    font-size: 16px !important;
    color: #999999;
    box-shadow: none !important;
    font-weight: normal !important;

    &--cancel {
      background: #FFFFFF;
      color: #000000;
    }

    &--delete {
      background: #009EFB !important;
      color: #FFF;
    }
  }
}

.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
