<template>
  <v-btn
      v-bind="$attrs"
      class="btn btn__text"
      color="#FFF"
      v-on="$listeners"
  >
      Ver Canales
  </v-btn>
</template>
<script>
export default {
  inheritAttrs: false
}
</script>
<style lang="scss" scoped>
  .btn {
    &__text {
       font-size: 16px;
       line-height: 19px;
       color: #009EFB !important;
     }
  }
</style>
