<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <div class="d-sm-flex flex-column align-center justify-space-between">
        <div class="ml-3 d-flex justify-space-between w-100">
          <div>
            <h2 class="font-weight-regular title">
              Secciones
            </h2>
            <h6 class="body-2 mb-1 subtitle-2 font-weight-light">
              Datos de registrados
            </h6>
          </div>
        </div>
        <v-spacer></v-spacer>
      </div>
      <!-- Table -->
      <v-data-table
          :headers="headerArray"
          :items="sections"
          :options.sync="options"
          :loading="loading"
          :items-per-page="pageSize"
          hide-default-footer
      >
        <template v-slot:item.nombre="{ item }">
          <div class="d-flex" style="cursor: pointer;"
               @click="$router.push({name: 'SectionDetail', params : {id: item.id }})">
            <h4 class="font-weight-medium text-no-wrap">
              {{ item.nombre }}
            </h4>
          </div>
        </template>
        <template v-slot:item.fecha_inicio="{ item }">
          <div class="d-flex justify-center" style="cursor: pointer;"
               @click="$router.push({name: 'SectionDetail', params : {id: item.id }})">
            <h4 class="font-weight-medium text-no-wrap">
              {{ getDateFormat(item.fecha_inicio) || '-' }}
            </h4>
          </div>
        </template>
        <template v-slot:item.fecha_fin="{ item }">
          <div class="d-flex justify-center" style="cursor: pointer;"
               @click="$router.push({name: 'SectionDetail', params : {id: item.id }})">
            <h4 class="font-weight-medium text-no-wrap">
              {{ getDateFormat(item.fecha_fin) || '-' }}
            </h4>
          </div>
        </template>
        <template v-slot:item.meta_alumnos_inscritos="{item}">
          <div class="d-flex justify-center" style="cursor: pointer;"
               @click="$router.push({name: 'SectionDetail', params : {id: item.id }})">
            <h4 class="font-weight-medium text-no-wrap">
              {{ item.numero_matriculas }}/{{ item.meta_alumnos_inscritos || '-' }}
            </h4>
          </div>
        </template>
        <template v-slot:item.monto_total_ganado="{item}">
          <div class="d-flex justify-center" style="cursor: pointer;"
               @click="$router.push({name: 'SectionDetail', params : {id: item.id }})">
            <h4 class="font-weight-medium text-no-wrap">
              {{ item.monto_total_ganado || 0 }}
            </h4>
          </div>
        </template>
        <template v-slot:item.alumnos_costo_cero="{item}">
          <div class="d-flex justify-center" style="cursor: pointer;"
               @click="$router.push({name: 'SectionDetail', params : {id: item.id }})">
            <h4 class="font-weight-medium text-no-wrap">
              {{ item.alumnos_costo_cero || 0 }}
            </h4>
          </div>
        </template>
        <template v-slot:item.canales="{item}">
          <WatchButton
              :loading="setLoadingModal(item)"
              @click="watchCanals(item.id)"
          />
        </template>

        <template v-slot:item.exportar_estudiantes="{item}">
          <v-btn
              :loading="item.loading || false"
              @click="exportStudents(item)"
              color="#FFF"
              class="download-button"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <div class="pagination d-flex justify-space-between ml-auto mr-0 mt-5">
            <p class="ma-auto">{{ page }} de {{ totalPages }}</p>
            <div>
              <v-btn icon @click="page--" :disabled="page === 1">
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn icon @click="page++" :disabled="page === totalPages">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <CanalsModal
        v-model="openModalCanals"
        persistent
        :detail="currentModal || []"
        @close="openModalCanals=false"
    />
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import WatchButton from '@/components/dashboardComponents/section/WatchButton.vue'
import CanalsModal from '@/components/dashboardComponents/section/CanalsModal.vue'
import {downloadCsv} from "../../utils/common";

export default {
  components: {
    WatchButton,
    CanalsModal
  },
  data: () => ({
    loading: false,
    page: 1,
    headerArray: [
      {text: 'Nombre de la seccion', value: 'nombre', align: 'start', sortable: false},
      {text: 'Fecha de inicio', value: 'fecha_inicio', align: 'center', sortable: false},
      {text: 'Fecha de fin', value: 'fecha_fin', align: 'center', sortable: false},
      {text: 'Meta de alumnos inscritos', value: 'meta_alumnos_inscritos', align: 'center', sortable: false},
      {text: 'Monto recaudado', value: 'monto_total_ganado', align: 'center', sortable: false},
      {text: 'Alumnos Costo 0', value: 'alumnos_costo_cero', align: 'center', sortable: false},
      {text: 'Ver canales', value: 'canales', align: 'center', sortable: false},
      {text: 'Exportar estudiantes', value: 'exportar_estudiantes', align: 'center', sortable: false},
    ],
    sections: [],
    pageSize: 20,
    totalPages: 1,
    options: {},
    next: true,
    sectionSelectedId: null,
    // modal configuration
    openModalCanals: false,
    currentModal: null,
    loadingModal: false,
    currentDetail: null
  }),
  async mounted() {
    await this.fetchSections()
  },
  watch: {
    page() {
      this.fetchSections()
    }
  },
  methods: {
    setLoadingModal(item) {
      return this.loadingModal && item.id === this.currentDetail
    },
    async fetchSections() {
      const response = await axios.get(`api/admin/secciones/?page=${this.page}&page_size=${this.pageSize}`)
      this.sections = response.data.results
      this.totalPages = Math.ceil(response.data.count / this.pageSize)
    },
    getDateFormat(date) {
      return moment(new Date(date)).format('DD/MM/YYYY')
    },
    async watchCanals(detail) {
      try {
        this.currentDetail = detail
        this.loadingModal = true
        const response = await axios.get(`api/admin/secciones/${detail}/canales_referencia/`)
        this.currentModal = response.data
        this.openModalCanals = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingModal = false
      }
    },
    async exportStudents(section) {
      try {
        this.$set(section, 'loading', true)
        const response = await axios.post(`api/admin/secciones/${section.id}/export_students/`)
        const filename = `Lista de estudiantes de la sección ${section.nombre}`
        downloadCsv(response.data, filename)
        section.loading = false
      } catch (e) {
        section.loading = false
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Ha ocurrido un error inesperado. Inténtelo de nuevo en unos minutos.'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  border: 1px solid #CED4DA !important;
  text-transform: none !important;
  box-shadow: none !important;
}

.download-button {
  border: 1px solid #CED4DA !important;
  text-transform: none !important;
  box-shadow: none !important;
  color: #009EFB !important;
}
</style>
